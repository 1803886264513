<template>
    <!-- 
	Options should be formatted like objects containing value and title, and optionally a description

	typeOptions() {
		return this.types.map(type => ({
			value: type.slug,
			title: type.title,
			description: type.description (optional)
		}))
	},

	The other props are described in the prop-section


 -->
    <div :class="width" class="relative" v-clicked-outside="{
        handler: clickedOutside,
        excluded_ids: excluded_ids,
    }">
        <div class="flex justify-center w-full">
            <button @click="toggleOpen()" :disabled="disabled" :id="_uid + '-button'" type="button"
                :class="[appliedButtonClasses, disabled ? disabled : '']"
                class="bg-white inline-flex w-full items-center justify-between rounded-md border px-3 text-sm font-medium leading-4 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-50 hover:text-gray-700 disabled:hover:text-gray-500 focus:bg-gray-50 focus:outline-none active:bg-gray-50">
                <p class="overflow-hidden truncate">{{ buttonText }}</p>
                <svg class="ml-2 -mr-0.5 h-2.5 w-2.5 fill-current" viewBox="0 0 451.847 451.847"
                    style="enable-background: new 0 0 451.847 451.847" xml:space="preserve">
                    <path
                        d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </svg>
            </button>
        </div>

        <!-- 
			NOTICE!
			This was disabled due to when the dropdown overflowed the screen, you couldnt scroll the background. only the dropdown. Which users didnt understand. Replaced with a clicked-outside handler.
			Full Screen Dropdown Overlay -->
        <!-- <div v-show="open" class="fixed inset-0 z-40" @click="open = false">
        </div> -->

        <transition enter-active-class="transition ease-out duration-200" enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
            leave-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <div v-show="open" class="w-full absolute mt-2 overflow-hidden rounded-md border shadow-lg"
                :class="[alignmentClasses, zIndex]" style="display: none">
                <div class="w-full rounded-md bg-white ring-1 ring-black ring-opacity-5" :class="contentClasses">
                    <div v-if="searchable" class="p-1 bg-gray-100">
                        <input v-model="searchQuery" type="text" placeholder="Sök..."
                            class="w-full p-2 rounded-md border-gray-200 bg-white" :class="[listTextSize]">
                    </div>
                    <div :class="maxHeight" class="flex w-full flex-col overflow-x-hidden overflow-y-auto">
                        <button @click.stop="toggleOption(option.value)" v-for="(option, index) in filteredOptions"
                            :key="option.value + '-' + index" :class="{
                                'bg-hs-green-500 bg-opacity-20':
                                    selected.includes(option.value),
                            }"
                            class="hover:text-hs-green-500 block w-full px-4 py-2 text-left text-sm leading-5 text-gray-700 transition duration-150 ease-in-out focus:bg-gray-100 focus:outline-none">
                            <div class="flex items-center justify-between">
                                <div>
                                    <p :class="[listTextSize]" v-html="highlightText(option.title)"></p>
                                    <p v-if="option.description" class="text-gray-400" v-text="option.description"></p>
                                </div>
                                <svg v-if="selected.includes(option.value)" class="text-hs-green-500 h-5 w-5"
                                    fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    stroke="currentColor" viewBox="0 0 24 24">
                                    <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        // if the opened dropdown should be aligned left or right
        align: {
            default: "right",
        },
        // The width of the button
        width: {
            default: "w-48",
        },
        // The max-height of the opened dropdown, will be scrollable
        maxHeight: {
            default: "max-h-screen-2/5",
        },
        maxHeightNotOpen: {
            default: "max-h-10",
        },
        // Available classes for the opened dropdown
        contentClasses: {
            default: () => ["bg-white"],
        },
        // Available classes for the dropdown-button
        buttonClasses: {
            default: () => ["py-2", "border-gray-300"],
        },
        // The available options, look at the top of the file for an example
        options: {
            default: () => [],
        },
        // A signle value or an array of values, should exist among the options values
        selectedOptions: {
            default: () => [],
        },
        // The text that is displayed when no option is selected
        noneSelected: {
            default: "Inget valt",
        },
        // If multiple options should be allowed
        multiple: {
            default: false,
        },
        // If the dropdown should display signs of an error (red border)
        hasError: {
            default: false,
        },
        // If the dropdown should be disabled
        disabled: {
            default: false,
        },
        zIndex: {
            default: 'z-4',
        },
        listTextSize: {
            default: 'text-base',
        },
        searchable: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            open: false,
            searchQuery: "",
            excluded_ids: [this._uid + "-button"],
        };
    },

    created() {
        const closeOnEscape = (e) => {
            if (this.open && e.keyCode === 27) {
                this.open = false;
            }
        };

        this.$once("hook:destroyed", () => {
            document.removeEventListener("keydown", closeOnEscape);
        });

        document.addEventListener("keydown", closeOnEscape);
    },

    methods: {
        toggleOption(value) {
            if (this.selected.includes(value)) {
                this.$emit(
                    "optionsSelected",
                    this.selected.filter((opt) => opt !== value)
                );
            } else {
                if (!this.multiple) {
                    this.$emit("optionSelected", value);
                    this.open = false;
                } else {
                    this.$emit(
                        "optionsSelected",
                        this.selected.concat([value])
                    );
                }
            }
        },

        highlightText(text) {
            if (!this.searchQuery) return text;
            const regex = new RegExp(`(${this.searchQuery})`, 'gi');
            return text.replace(regex, '<span class="bg-blue-200">$1</span>');
        },

        toggleOpen() {
            if (!this.disabled) {
                this.searchQuery = "";
                this.open = !this.open;
            }
        },

        clickedOutside() {
            if (this.open) {
                this.open = false;
            }
        },
    },

    watch: {
        options() {
            if (this.selected.length && this.options.length) {
                let removedOptions = this.selected.filter((selected) => {
                    return !this.options.some((opt) => opt.value === selected);
                });

                // Will deselect any selected options that are not present in the
                // provided options
                if (removedOptions.length) {
                    removedOptions.forEach((opt) => {
                        if (this.multiple) {
                            this.$emit(
                                "optionsSelected",
                                this.selected.filter((selected) => {
                                    return !this.options.some(
                                        (opt) => opt.value === selected
                                    );
                                })
                            );
                        } else {
                            this.$emit("optionSelected", null);
                            this.open = false;
                        }
                    });
                }
            }
        },
    },

    computed: {
        buttonText() {
            if (this.selected.length === 0) {
                return this.noneSelected;
            }

            if (this.selected.length === 1 && this.options.length) {
                let found = this.options.find(
                    (opt) => opt.value === this.selected[0]
                );
                return found ? found.title : "Ogiltigt alternativ";
            }
            return this.selected.length + " st valda";
        },

        filteredOptions() {
            return this.options.filter((option) => {
                return this.searchQuery ? option?.title?.toLowerCase().includes(this.searchQuery.toLowerCase()) : true;
            });
        },

        alignmentClasses() {
            if (this.align === "left") {
                return "origin-top-left left-0";
            } else if (this.align === "right") {
                return "origin-top-right right-0";
            } else {
                return "origin-top";
            }
        },

        appliedButtonClasses() {
            let classes = [];

            classes = classes.concat(this.buttonClasses);

            if (this.hasError) {
                classes.push("border-red-300");
            }
            return classes;
        },

        selected() {
            if (!Array.isArray(this.selectedOptions)) {
                if (
                    this.selectedOptions !== null &&
                    this.selectedOptions !== undefined &&
                    this.selectedOptions !== ""
                ) {
                    return [this.selectedOptions];
                } else {
                    return [];
                }
            }
            return this.selectedOptions;
        },
    },
};
</script>
